export const workLocationOptions = [
  { label: 'Remote', value: 'remote' },
  { label: 'Onsite', value: 'onsite' },
  { label: 'Hybrid', value: 'hybrid' },
];

export const statusOptions = [
  { label: 'Ongoing', value: 'ongoing' },
  { label: 'Closed', value: 'closed' },
  { label: 'Draft', value: 'draft' },
];

export const jobTypeOptions = [
  { label: 'Full time', value: 'full-time' },
  { label: 'Part time', value: 'part-time' },
  { label: 'Contract', value: 'contract' },
  { label: 'Temporary', value: 'temporary' },
  { label: 'Internship', value: 'internship' },
  { label: 'Volunteer', value: 'volunteer' },
];

export const categoryOptions = [
  { label: 'Clinical', value: 'clinical' },
  { label: 'Tech', value: 'tech' },
  { label: 'Growth', value: 'growth' },
  { label: 'Design', value: 'design' },
  { label: 'Product', value: 'product' },
  { label: 'Sales', value: 'sales' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Research', value: 'research' },
  { label: 'Operations', value: 'operations' },
  { label: 'Finance', value: 'finance' },
  { label: 'People', value: 'people' },
];

export const experienceOptions = [
  { label: 'Entry level', value: 'entry-level' },
  { label: 'Mid level', value: 'mid-level' },
  { label: 'Senior level', value: 'senior-level' },
];

export const sourceOptionsPrescriptions = [
  {
    value: 'doctor',
    label: 'Doctor',
  },
  {
    value: 'nurse',
    label: 'Nurse',
  },
  {
    value: 'self-prescribed',
    label: 'Self Prescribed',
  },
  {
    value: 'pharmacy',
    label: 'Pharmacy',
  },
  {
    value: 'family-and-friends',
    label: 'Family & Friends',
  },
  {
    value: 'chemist',
    label: 'Chemist',
  },
];
